import { atom, useAtom } from 'jotai';
import { MapRef } from 'react-map-gl';
import { propertiesAtom } from './properties';

export const appLoadingAtom = atom<boolean>((get) => {
  const properties = get(propertiesAtom);
  return !properties.length;
});

export const useAppLoading = () => useAtom(appLoadingAtom);

export const mainMenuOpenAtom = atom<boolean>(true);
export const useMainMenuOpen = () => useAtom(mainMenuOpenAtom);

export const mapAtom = atom<MapRef | null>(null);
export const useMap = () => useAtom(mapAtom);
